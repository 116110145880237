<template>
    <div class="summary">
        <div class="summary-container">
            <ul>
                <li v-for="(module, idx) in modules" v-bind:key="idx"><strong>{{idx+1}}</strong>
                    <h5>{{module.title}}</h5>
                    <ul>
                        <li class="mr-4" v-for="(question, idx) in module.questions" v-bind:key="idx">
                            <label v-html="question.text"></label>
                            <span class="summary-answer" v-html="getAnswer(question.id)"> </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div id="bottom-wizard">
            <button
                    v-on:click="sendData"
                    type="button"
                    name="forward"
                    class="forward ">
                <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Invia Risposte
            </button>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Summary",
    data: function () {
      return {
        loading: false
      }
    },
    methods: {
      getAnswer: function( questionId ) {
        const answers = this.$store.getters.getAnswers;
        const answer = answers.find( x => x.questionId === questionId);

        if(!answer)
          return '';

        return answer.answerText;
      },
      sendData: function() {
        if(!this.loading)
            this.$store.dispatch('submitData')

        this.loading = true;
      },
    },
    computed: {
      modules: function() {
        return this.$store.getters.getAllModules;
      },

    }
  }
</script>

<style scoped>

    .summary-container {
        max-height: 600px;
        overflow-y: scroll;
    }

</style>
