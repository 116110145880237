<template>
    <div class="home">
        <div class="container-fluid full-height">
            <div class="row row-height">
                <div class="col-lg-5 content-left">
                    <div class="content-left-wrapper">
                        <a href="/" id="logo"><img src="../assets/logo_negativo.png" alt="" width="175"></a>
                        <SocialIcons></SocialIcons>
                        <div>
                            <figure><img src="../assets/complete.png" alt="" class="img-fluid"></figure>
                            <h2>Grazie!</h2>
                            <h2>
                                Hai completato il questionario
                            </h2>
                            <p>
                                Prima di inviare il risultato ti invitiamo a revisionare le risposta date per ogni modulo.
                                Una volta completata la revisione ed inviate le risposte, riceverai una mail di conferma
                                dell'emissione del benchmark qualitativo che potrai consultare tramite un link.
                            </p>
                        </div>
                        <div class="copy">© 2020 RetImpresa Reference</div>
                    </div>
                </div>
                <!-- /content-left -->

                <div class="col-lg-7 content-right" id="start">
                    <div class="end-survey">
                        <Summary></Summary>
                    </div>
                </div>
                <!-- /content-right-->
            </div>
            <!-- /row-->
        </div>
        <!-- /container-fluid -->
    </div>
</template>

<script>
  import SocialIcons from "../components/SocialIcons";
  import Summary from "../components/Summary";
  export default {
    name: "EndSurvey",
    components: {Summary, SocialIcons}
  }
</script>

<style scoped>

</style>
